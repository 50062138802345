import noop from 'lodash/noop';
import React, { PropsWithChildren, ReactElement, useCallback } from 'react';
import { Spacing } from '../../design-system/spacing';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { styled } from '../../utils/styled';
import { Box } from '../Box';
import { Button } from '../Button';
import { ClickAwayListener } from '../ClickAwayListener';
import { Divider } from '../Divider';
import { CloseIcon } from '../Icons/CloseIcon';
import { Stack } from '../Stack';
import { SwipeableDrawer } from '../SwipeableDrawer';
import {
  ContentWrapper,
  Puller,
  StyledButtonContent,
} from './RightSideDrawer.styles';

const StyledStack = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(Spacing.Medium)};
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

const StyledContainerPuller = styled(Stack)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
`;

interface RightSideDrawerProps {
  readonly header?: ReactElement;
  readonly isOpen?: boolean;
  readonly actionButtons?: ReactElement;
  readonly handleClose: () => void;
  readonly handleOpen?: () => void;
}

export function RightSideDrawer({
  isOpen,
  children,
  actionButtons,
  handleClose,
  header,
  handleOpen = noop,
}: PropsWithChildren<RightSideDrawerProps>) {
  const { isLgUp } = useBreakpoints();

  const onClickAway = useCallback(() => {
    if (isOpen) {
      handleClose();
    }
  }, [handleClose, isOpen]);

  return (
    <ClickAwayListener mouseEvent="onMouseUp" onClickAway={onClickAway}>
      <div>
        <SwipeableDrawer
          anchor={isLgUp ? 'right' : 'bottom'}
          isLgUp={isLgUp}
          onClose={handleClose}
          onOpen={handleOpen}
          open={isOpen}
          variant={isLgUp ? 'persistent' : 'temporary'}
        >
          <StyledContainerPuller>
            {!isLgUp && <Puller />}
            {header ? (
              <StyledStack>
                <Box>{header}</Box>
                <Button
                  color="neutral"
                  onClick={handleClose}
                  startIcon={
                    <CloseIcon aria-label="Close Drawer" title="Close Drawer" />
                  }
                  variant="text"
                />
              </StyledStack>
            ) : null}
          </StyledContainerPuller>
          <Divider noMargin />
          <ContentWrapper>{children}</ContentWrapper>
          {actionButtons ? (
            <>
              <Divider noMargin />
              <StyledButtonContent>{actionButtons}</StyledButtonContent>
            </>
          ) : null}
        </SwipeableDrawer>
      </div>
    </ClickAwayListener>
  );
}
