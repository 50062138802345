import { InputProps, TextFieldProps } from '@mui/material';
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import { Kind } from '../../types/kind';
import { WithRequired } from '../../utils/withRequired';

export enum FieldTypes {
  password = 'password',
  text = 'text',
  currency = 'currency',
  select = 'select',
  phone = 'phone',
  date = 'date',
}

export interface FieldProps
  extends Pick<
    WithRequired<TextFieldProps, 'onChange'>,
    | 'label'
    | 'disabled'
    | 'onChange'
    | 'value'
    | 'name'
    | 'onBlur'
    | 'slotProps'
  > {
  readonly type: FieldTypes;
  readonly kind?: Kind;
  readonly value: string;
  readonly iconLeft?: ReactNode;
  readonly menuItems?: { key: string; value: string; component?: ReactNode }[];
  readonly errorMsg?: string;
  readonly showPasswordThermometer?: boolean;
  readonly passwordStrength?: string;
  readonly showRequirementTooltip?: boolean;
  readonly mb?: string | number;
  readonly mt?: string | number;
  readonly ml?: string | number;
  readonly mr?: string | number;
  readonly pt?: string | number;
  readonly pb?: string | number;
  readonly pl?: string | number;
  readonly pr?: string | number;
  readonly margin?: string | number;
  readonly padding?: string | number;
  readonly width?: string | number;
  readonly inputProps?: Partial<
    InputProps &
      React.HTMLAttributes<HTMLInputElement> & { 'data-testid'?: string }
  >;
  readonly handleDatePickerChange?: (
    value: Dayjs | null,
    _?: PickerChangeHandlerContext<DateValidationError>,
  ) => void;
}
