import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  ClaimscoreLogoSmall,
  ClipboardIcon,
  DashboardIcon,
  Flags,
  MenuOpenIcon,
  NavItem,
  Spacing,
  Stack,
  styled,
  SupervisorAccountIcon,
  useBreakpoints,
  useFlag,
} from 'ui';
import { ClaimscoreLogoLarge } from 'ui/src/components/Icons/ClaimscoreLogoLarge';
import { ColorPaper200 } from 'ui/src/design-system/colors';
import { useUserOrgCase } from '@context/UserOrgCaseContext';
import { PATHS } from '@utils/paths';
import { SIDENAVBAR_CONTENT_WRAPPER } from './SideNavbar.testIds';

const ListBox = styled(Box)`
  margin-top: ${({ theme }) => theme.spacing(Spacing.Large)};
`;
const LogoBox = styled(Box)`
  padding-left: ${({ theme }) => theme.spacing(Spacing.Small)};
`;
const SidebarBox = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(Spacing.Medium)};
  margin-top: ${({ theme }) => theme.spacing(Spacing.Medium)};
`;

const StyledMenuOpenIcon = styled(MenuOpenIcon)`
  cursor: pointer;
`;

const SIDE_NAVBAR_ITEMS = [
  {
    text: 'Dashboard',
    icon: <DashboardIcon />,
    to: `/dashboard`,
  },
];

const ADMIN_ITEMS = [
  ...SIDE_NAVBAR_ITEMS,
  {
    text: 'Permissions',
    icon: <SupervisorAccountIcon />,
    to: `/permissions/organization`,
  },
];

const REPORTS_ITEM = {
  text: 'Reports',
  icon: <ClipboardIcon />,
  to: PATHS.PRIVATE_BASE.scheduledReports,
};

export interface MainSideNavbarContentProps {
  readonly open?: boolean;
  readonly onClose?: () => void;
}

export function MainSideNavbarContent({
  open,
  onClose,
}: MainSideNavbarContentProps) {
  const { isLgUp } = useBreakpoints();
  const { isAdminOrOwnerInAnyOrgOrCase } = useUserOrgCase();
  const { search } = useLocation();
  const isScheduledReportsEnabled = useFlag(Flags.scheduleReports);

  const getSideNavbarBoxContent = useMemo(() => {
    if (open) {
      return (
        <LogoBox>
          <ClaimscoreLogoLarge color={ColorPaper200} />
          {!isLgUp && (
            <StyledMenuOpenIcon aria-label="menu-open-icon" onClick={onClose} />
          )}
        </LogoBox>
      );
    }
    return (
      <LogoBox>
        <ClaimscoreLogoSmall color={ColorPaper200} />;
      </LogoBox>
    );
  }, [open, isLgUp, onClose]);

  const getSideNavbarItems = useMemo(() => {
    const sideNavbarItems = isAdminOrOwnerInAnyOrgOrCase
      ? ADMIN_ITEMS
      : SIDE_NAVBAR_ITEMS;

    if (!isScheduledReportsEnabled) {
      sideNavbarItems.push(REPORTS_ITEM);
    }

    return sideNavbarItems.map(({ text, icon, to }) => (
      <NavItem
        icon={icon}
        key={text}
        kind="dark"
        label={text}
        to={`${to}${search}`}
      />
    ));
  }, [isAdminOrOwnerInAnyOrgOrCase, isScheduledReportsEnabled, search]);

  return (
    <Stack data-testid={SIDENAVBAR_CONTENT_WRAPPER}>
      <SidebarBox>{getSideNavbarBoxContent}</SidebarBox>
      <ListBox>{getSideNavbarItems}</ListBox>
    </Stack>
  );
}
