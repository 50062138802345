import { MenuList, Stack, Typography } from '@mui/material';
import React from 'react';
import { StyledMenuItem } from '../Field/Field.styles';
import { StyledSwipeableDrawer } from '../SwipeableDrawer/SwipeableDrawer';

interface SelectDrawerProps {
  readonly label: string;
  readonly isOpen: boolean;
  readonly handleOpen: () => void;
  readonly handleClose: () => void;
  readonly options: { key: string; value: string }[];
  readonly value: string;
  readonly onChange: (value: string) => void;
}

export function SelectDrawer({
  label,
  value,
  options,
  isOpen,
  handleOpen,
  handleClose,
  onChange,
}: SelectDrawerProps) {
  return (
    <StyledSwipeableDrawer
      anchor="bottom"
      isLgUp={false}
      onClose={handleClose}
      onOpen={handleOpen}
      open={isOpen}
      variant="temporary"
    >
      <Stack direction="column" width="100%">
        <Typography padding={3} variant="h4">
          {label}
        </Typography>
        <MenuList>
          {options.map(({ key, value: optionLabel }) => (
            <StyledMenuItem
              key={key}
              onClick={() => onChange(key)}
              selected={key === value}
            >
              <Typography variant={key === value ? 'bodyStrong' : 'body'}>
                {optionLabel}
              </Typography>
            </StyledMenuItem>
          ))}
        </MenuList>
      </Stack>
    </StyledSwipeableDrawer>
  );
}
