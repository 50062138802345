import React from 'react';
import { Box, Spacing, styled, Typography } from 'ui';

export interface ChipLabelProps {
  readonly label: string;
  readonly value: string | [number, number] | undefined;
}

const StyledBox = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
`;
const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'bold',
})<{ bold?: boolean }>`
  text-transform: capitalize;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

export function ChipLabel({ label, value }: ChipLabelProps) {
  return (
    <StyledBox>
      <StyledTypography bold variant="subtitle">
        {label}
      </StyledTypography>
      <StyledTypography variant="body">{value}</StyledTypography>
    </StyledBox>
  );
}
