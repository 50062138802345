import {
  SwipeableDrawer as SwipeableDrawerMui,
  SwipeableDrawerProps as SwipeableDrawerPropsMui,
} from '@mui/material';
import React from 'react';
import { styled } from '../../utils/styled';

export interface SwipeableDrawerProps extends SwipeableDrawerPropsMui {
  readonly isLgUp?: boolean;
}

export const StyledSwipeableDrawer = styled(SwipeableDrawerMui, {
  shouldForwardProp: (prop) => prop !== 'isLgUp',
})<{ isLgUp?: boolean }>`
  .MuiModal-backdrop {
    background-color: #fff0;
  }

  .MuiDrawer-paper {
    width: ${({ isLgUp }) => (isLgUp ? '600px' : '100vw')};
    height: ${({ isLgUp }) => (isLgUp ? 'calc(100vh - 144px)' : null)};
    border-top: 0;
    border-top-right-radius: ${({ theme, isLgUp }) =>
      isLgUp ? 0 : theme.borderRadii.mobileDrawer};
    border-top-left-radius: ${({ theme, isLgUp }) =>
      isLgUp ? 0 : theme.borderRadii.mobileDrawer};
    top: ${({ isLgUp }) => (isLgUp ? '145px' : null)};
    box-shadow: ${({ theme }) => theme.boxShadow};
    border-left: 0;
  }
`;

export function SwipeableDrawer({ children, ...rest }: SwipeableDrawerProps) {
  return <StyledSwipeableDrawer {...rest}>{children}</StyledSwipeableDrawer>;
}
