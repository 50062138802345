const TINY_NUMBER = 0.5;
const X_LARGE_NUMBER = 4.5;

export enum Spacing {
  None = 0,
  Tiny = TINY_NUMBER,
  Small = 1,
  Medium = 2,
  Large = 3,
  XLarge = X_LARGE_NUMBER,
  XXLarge = 6,
  Huge = 8,
}
